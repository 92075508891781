import { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { getFormatedDate } from "../../../utils/functions/table";
import { subDays } from "date-fns";
import { updateConfig } from "../../../store/slices/CMS/bannerSlice";
import {
  useGetBannerListDataQuery,
  useUpdateBannerListFieldsDataMutation,
} from "../../../store/queries/cms";
import { deleteBanner, updateStatus, viewBanner } from "./api";
import { toast } from "react-toastify";
import Style from "./banner.module.scss";
import Select from "react-select";

const useBannerImage = () => {
  const dispatch = useDispatch();
  const bannerState = useSelector((state) => state.banner);
  const { showCreateModal, magazineId, isEdit, itemId } = useSelector(
    (state) => state.banner
  );
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState({});
  const [date, setDate] = useState({
    startDate: bannerState.startDate,
    endDate: bannerState.endDate,
    key: "selection",
  });
  const {
    data: banners = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetBannerListDataQuery({
    sort_by: bannerState.sortBy,
    sort_order: bannerState.sortOrder,
    search: bannerState.search,
    start: getFormatedDate(bannerState.startDate),
    end: getFormatedDate(bannerState.endDate),
    page_size: bannerState.currentPageSize,
    page: bannerState.currentPage,
  });
  const [updateBannerFields] = useUpdateBannerListFieldsDataMutation();
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  let actionOptions = [{ label: "Edit Columns", value: 0 }];
  const options = [
    { value: 1, label: "Live" },
    { value: 0, label: "Hold" },
  ];

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = banners?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [banners]);

  const hasCreatePermission = useMemo(() => {
    let permission = banners?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [banners]);
  const hasDeletePermission = useMemo(() => {
    let permission = banners?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );

    return permission?.[0]?.can_delete ?? 0;
  }, [banners]);
  const hasStatusChangePermission = useMemo(() => {
    let permission = banners?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_change_status")
    );

    return permission?.[0]?.can_change_status ?? 0;
  }, [banners]);

  const hasEditPermission = useMemo(() => {
    let permission = banners?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit;
    // eslint-disable-next-line
  }, [banners]);

  const hasUpdatePermission = useMemo(() => {
    let permission = banners?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update;
    // eslint-disable-next-line
  }, [banners]);

  const handleSort = (label) => {
    if (bannerState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = bannerState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };
  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
        state.isEdit = false;
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        // state.selectedItemsDetails = ""
      })
    );
  };
  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = true;
        state.showCreateModal = true;
        state.itemId = e?.[0];
      })
    );
    viewBanner(e?.[0]).then((response) => {
      if (response?.status === 200) {
        dispatch(
          updateConfig((state) => {
            state.selectedItemsDetails = response?.data?.data ?? "";
          })
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const handleEditClick = () => {
    // dispatch(
    //   updateConfig((state) => {
    //     state.selectedItemsDetails = magazineViewData?.data ?? "";
    //   })
    // );
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };
  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };
  const handleChange = (data,value) => {
    if(value?.value!==data?.is_active){
      let params = {
        banner_id: data?._id,
        status:value?.value,
      };
      updateStatus(params).then((response) => {
        if (response?.data?.success) {
          toast.success(response?.data?.message);
          refetch();
        } else {
          toast.error(response?.data?.message??"Something went wrong");
        }
      });
    }
    // refetch()
    // dispatch(
    // expertise.util.updateQueryData(
    //     "getExpertiseData",
    //     {
    //       items_per_page: expertiseState.currentPageSize,
    //         current_page: expertiseState.currentPage,
    //         sort_by: expertiseState.sortBy,
    //         sort_order: expertiseState.sortOrder,
    //         search: expertiseState.search,
    //         status:expertiseState?.status,
    //         service_type_id:expertiseState?.service_type_id,
    //         service_category_id:expertiseState?.service_category_id
    //             },
    //     (cachedData) => {
    //       let updatedCachedData = { ...current(cachedData) };
    //       let newResult = updatedCachedData?.data?.data?.map((res) =>
    //         res?._id === data?._id
    //           ? {
    //               ...res,
    //               status: data?.status === 1 ? 0 : 1,
    //             }
    //           : res
    //       );
    //       updatedCachedData = {
    //         ...updatedCachedData,
    //         data: {
    //           ...updatedCachedData.data,
    //           data: newResult,
    //         },
    //       };
    //       return updatedCachedData;
    //     }
    //   )
    // );
  };
  let typeOption = [
    { label: "Default", value: 1 },
    { label: "Offer", value: 2 },
  ];
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      full_image: (feild, data) => (
        <>
          <p
            className="pro-mb-0 pro-pnt"
            onClick={() => handleViewImage(data[feild]?.url, "desktop")}
          >
            {data[feild]?.name ?? ""}
          </p>
        </>
      ),
      type: (feild, data) => (
          <p>
            {typeOption?.find((item)=>(item?.value===data[feild]))?.label  ?? ""}
          </p>
      ),
      popup_image: (feild, data) => (
        <>
          <p
            className="pro-mb-0 pro-pnt"
            onClick={() => handleViewImage(data[feild]?.url, "popup")}
          >
            {data[feild]?.name ?? ""}
          </p>
        </>
      ),
      responsive_image: (feild, data) => (
        <>
          <p
            className="pro-mb-0 pro-pnt"
            onClick={() => handleViewImage(data[feild]?.url, "mobile")}
          >
            {data[feild]?.name ?? ""}
          </p>
        </>
      ),
      is_active: (feild, data) => {
        return (
          <>
            {hasStatusChangePermission ===1?<div className="col-12 no-border" style={{ minWidth: "222px" }}>
              <Select
                options={options}
                value={options.find((item)=>item?.value===data[feild])}
                onChange={(value)=>handleChange(data, value)}
                className={Style.reactSelectContainer}
                classNamePrefix="pro-input"
                   menuPosition="fixed"
              />
            </div>: 
             <div
              className={`pro-badge  ${
                data[feild] === 1
                  ? "badge-grey-outline"
                  : "badge-danger-outline"
              }`}
            >
              {options.find((item)=>item?.value===data[feild])?.label}
            </div>}
          </>
        );
      },
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleViewImage = (imageUrl, mode) => {
    setShowImageModal(() => true);
    setImageData({ data: imageUrl, type: mode });
  };
  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };
  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };
  const handleClearClick = () => {
    setDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = subDays(new Date(), 30);
        state.endDate = new Date();
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.magazineId = "";
        state.invoiceLoading = false;
      })
    );
  };
  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = e?.[0];
      })
    );
  };
  const handleDeleteCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = "";
        state.itemData = "";
      })
    );
  };
  const handleDeleteAction = () => {
    if (hasDeletePermission) {
      deleteBanner(itemId).then((response) => {
        if (response?.status === 200) {
          toast.success("Deleted");
          refetch();
          handleDeleteCloseModal();
          dispatch(updateConfig((state) => (state.clearSelection = true)));
          const clearSelection = setTimeout(() => {
            dispatch(updateConfig((state) => (state.clearSelection = false)));
            clearTimeout(clearSelection);
          }, 500);
        } else {
          toast.error("Something went wrong");
          handleDeleteCloseModal();
        }
      });
    } else {
      toast.error("Dont have an access to Delete");
    }
  };

  return {
    banners,
    bannerState,
    currentPage: bannerState.currentPage,
    showCreateModal,
    hasCreatePermission,
    isFetching,
    isLoading,
    date,
    hasDeletePermission,
    showEditModal,
    tableFields,
    paginationOptions,
    magazineId,
    actionOptions,
    showImageModal,
    imageData,
    hasEditPermission,
    hasUpdatePermission,
    isEdit,
    handleDeleteAction,
    handleDeleteCloseModal,
    handleDeleteModal,
    handleDateRangeChange,
    handleEditAction,
    closeImageModal,
    handleActionChange,
    refetch,
    updateBannerFields,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleClearClick,
  };
};

export default useBannerImage;
