import { getAxiosInstance } from "../../../api";

export const createBanner= async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/banners/create`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateBanner = async (body) => {
  const api = await getAxiosInstance();;
  try {
    const response = await api.post(`/admin/banners/update`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const viewBanner = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/banners/view?banner_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`/admin/banners/status/change`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const deleteBanner = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/admin/banners/delete?banner_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};