import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/payment/transactionsSlice";
import {
  getPackagesData,
  getPaymentModes,
  getTypesData,
} from "./api.js";
import { useSelector } from "react-redux";
import { useAddPaymentMutation, useUpdatedPaymentMutation } from "../../../store/queries/payment";
import { profile } from "../../../store/queries/Profile/index.js";
import { getUserDetails } from "../Transactions/api.js";

const useAddPaymentForm = ({
  activeProfile,
  profilePayment,
  refetch,
  setSkip,
  selectedUser,
  setSelectedUser,
}) => {
  const [packageData, setPackageData] = useState([]);
  const [paymentModes, setPaymentModes] = useState([]);
  const [types, setTypes] = useState([]);
  const [addPayment, { isLoading }] = useAddPaymentMutation();
  const [updatePayment, { isUpdateLoading}] = useUpdatedPaymentMutation();
  // const [userData, setUserData] = useState([]);

  useEffect(() => {
    getPackagesData().then((response) => {
      setPackageData(response?.data?.data);
    });
    getPaymentModes().then((response) => {
      setPaymentModes(response?.data);
    });
    getTypesData().then((response) => {
      setTypes(response?.data?.data?.type);
    });
  }, []);

  const [errors, setErrors] = useState("");
  const [message, setMessage] = useState("");
  const imageFileInputRef = useRef(null);
  const { selectedUserId, userData, selectedItemsDetails, is_edit, transactionId } = useSelector(
    (state) => state.transactions
  );


  const dispatch = useDispatch();

  let addPaymentVal = Yup.object({
    amount: Yup.string().required("*Required"),
    payment_mode: Yup.string().required("*Required"),
    package_id: Yup.string().required("*Required"),
    user_id: !is_edit && Yup.string().required("*Required"),
    subscription_type: Yup.string().required("*Required"),
    // payment_proofs: Yup.string().required("*Required"),
  });
  const formik = useFormik({
    initialValues: {
      user_name: is_edit ? `${selectedItemsDetails?.user?.profile_id}(${selectedItemsDetails?.user?.first_name} ${selectedItemsDetails?.user?.last_name})` : "",
      user_id: "",
      package_id: is_edit ? selectedItemsDetails?.package_id : "",
      payment_mode: is_edit ? selectedItemsDetails?.payment_mode : "",
      amount: is_edit ? selectedItemsDetails?.amount : "",
      payment_proofs: is_edit ? selectedItemsDetails?.proof : "",
      note: is_edit ? selectedItemsDetails?.note : "",
      subscription_type: is_edit ? selectedItemsDetails?.subscription_type : "",
    },

    validationSchema: addPaymentVal,

    enableReinitialize: true,

    onSubmit: (values) => {
      try {
        let obj = {
          ...values,
          user_id: profilePayment ? activeProfile : values.user_id,
        };

        let newObj = Object.entries(obj);

        newObj = newObj
          .filter(
            (item) =>
              item[1] !== undefined && item[1] !== "" && item[1] !== null
          )
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

        let formData = new FormData();
        Object.keys(newObj).forEach((key) => {
          return formData.append(key, newObj[key]);
        });
        if(is_edit) {
          let updateData = {
            payment_transaction_id: transactionId,
            amount: values?.amount
          }
          updatePayment(updateData).then((response) => {
            setMessage("");
            setErrors("");
            if (response?.data?.status_code === 200) {
              refetch?.();
              dispatch(profile?.util?.invalidateTags(["Overview"]));
              dispatch(
                updateConfig((state) => {
                  state.is_edit = false;
                  state.selectedUserId = "";
                  state.selectedItemsDetails = "";
                  state.clearSelection = true;
                })
              );
              submitForm();
            } else if (response?.error?.data?.status_code === 400) {
              if(response?.error?.data?.message?.age[0]){
                toast.error(`${response?.error?.data?.message?.age[0]}`); 
              }else{
                toast.error(`${response?.error?.data?.message}`);
              }
              formik.setErrors(response?.error?.data?.message);
            } else {
              toast.error("Failed to Add Payment");
            }
          });
        }
        else {
          addPayment(formData).then((response) => {
            setMessage("");
            setErrors("");
            if (response?.data?.status_code === 200) {
              refetch?.();
              dispatch(profile?.util?.invalidateTags(["Overview"]));
              submitForm();
            } else if (response?.error?.data?.status_code === 400) {
              if(response?.error?.data?.message?.age[0]){
                toast.error(`${response?.error?.data?.message?.age[0]}`); 
              }else{
                toast.error(`${response?.error?.data?.message}`);
              }
              formik.setErrors(response?.error?.data?.message);
            } else {
              toast.error("Failed to Add Payment");
            }
          });
        }

        
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("user_id", selectedUserId);

    if (!selectedUserId) {
      dispatch(
        updateConfig((state) => {
          state.userData = [];
        })
      );
    }
    //eslint-disable-next-line
  }, [selectedUserId]);

  useEffect(() => {
    if (profilePayment) {
      formik.setFieldValue("user_id", activeProfile);
    }
    //eslint-disable-next-line
  }, [activeProfile]);

  const submitForm = () => {
    formik.resetForm();

    if (!profilePayment) {
      dispatch(
        updateConfig((state) => {
          state.showCreateModal = false;
          state.selectedUserId = "";
        })
      );
    } else {
      formik.setFieldValue("package_id", "");
      formik.setFieldValue("payment_mode", "");
      formik.setFieldValue("amount", "");
      formik.setFieldValue("payment_proofs", "");
      formik.setFieldValue("note", "");
      formik.setFieldValue("subscription_type", "");
      dispatch(
        updateConfig((state) => {
          state.selectedUserId = activeProfile;
        })
      );
    }

    toast.success("Successfully submitted");
  };

  const handleCloseModal = () => {
    if (profilePayment) {
      formik.setFieldValue("package_id", "");
      formik.setFieldValue("payment_mode", "");
      formik.setFieldValue("amount", "");
      formik.setFieldValue("payment_proofs", "");
      formik.setFieldValue("note", "");
      formik.setFieldValue("subscription_type", "");
    }
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.selectedUserId = "";
        state.is_edit = false;
        state.transactionId = "";
        state.selectedItemsDetails = "";
        state.clearSelection = true;
      })
    );
  };
  const packagesOptions = useMemo(
    () =>
      packageData?.map((opt) => {
        return {
          value: opt.name,
          label: opt.name,
          id: opt._id,
          type: opt.type,
          price: opt.price,
        };
      }),
    [packageData]
  );

  const paymentsOptions = useMemo(
    () =>
      paymentModes?.map((opt) => {
        return { value: opt.name, label: opt.name, id: opt.id };
      }),
    [paymentModes]
  );

  const typesOptions = useMemo(
    () =>
      types?.map((opt) => {
        return { value: opt.name, label: opt.name, id: opt.id };
      }),
    [types]
  );
  const useDataOptions = useMemo(
    () =>
      userData?.map((opt) => {
        return { value: opt.value, label: opt.value, id: opt._id };
      }),
    [userData]
  );

  const handleInputChange = (item) => {
    if (item?.length >= 2) {
      getUserDetails({ search: item }).then((response) => {
        // setUserData(response?.data?.data);
        dispatch(
          updateConfig((state) => {
            state.userData = response?.data?.data;
          })
        );
      });
    }
  };

  const handleUserId = (value) => {
    formik.setFieldValue("user_id", value?.id);
    setSelectedUser(value?.id);
    setSkip(() => false);
    dispatch(
      updateConfig((state) => {
        state.selectedUserId = value?.id;
      })
    );
  };

  const handleProofImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("payment_proofs", e?.target?.files?.[0]);
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError(
        "payment_proofs",
        "The image must be less than 5MB in size."
      );
    }
  };
  return {
    errors,
    message,
    formik,
    isLoading,
    userData,
    selectedUserId,
    imageFileInputRef,
    packagesOptions,
    paymentsOptions,
    useDataOptions,
    typesOptions,
    handleProofImage,
    handleUserId,
    handleCloseModal,
    handleInputChange,
    is_edit
  };
};

export default useAddPaymentForm;
